<template>
<div>

  <b-modal id="modal-delete-account" centered hide-footer title-class="h3">
    <template v-slot:modal-title>
      Are you sure?
    </template>
    <div class="d-block">
      <p>Are you sure you want to delete this account?</p>
      <p><strong>{{userDisplay}}</strong></p>
      <p>This action is irreversible.</p>
      <label for="verificationCode">Type in "DELETE" to activate the delete button</label>
      <input :value="deleteBlocker.toUpperCase()" @input="deleteBlocker = $event.target.value.toUpperCase()" type="text" placeholder="" class="form-control">
    </div>
    <div class="d-block mt-3">
      <div class="d-flex justify-content-between">
        <button class="btn btn-block text-dark btn-lg btn-link" v-on:click="$bvModal.hide('modal-delete-account')">Cancel</button>
        <button class="btn btn-block btn-lg btn-danger ml-1" v-on:click="deleteAccount()" :disabled="deleteBlocker !== 'DELETE'">Yes, delete</button>
      </div>
    </div>
  </b-modal>

  <div class="container-fluid wrapper-medium mt-3">

    <div class="row mb-3">
      <div class="col-12 col-md-6 mb-3">
          <b-link class="btn btn-link btn-sm pl-0" to="/members-management"><span class="icon icon-left-open mr-2 pl-0"></span>Members management</b-link>
          <h2 class="anim-load1 mt-2 mb-3">Edit member account</h2>
          <p>View and edit member account.</p>
          <hr>
          <h4>{{userDisplay}}</h4>
          <h6>Account status <span class="badge badge-success">Active</span></h6>
          <hr>
          <h5 class="mb-3">Danger zone</h5>
          <b-link class="btn btn-danger btn-sm" v-on:click="$bvModal.show('modal-delete-account')">Delete this account</b-link>
      </div>
      <div class="col-12 col-md-6">
        <div class="card mb-3">
          <div class="card-body">
            <ValidationObserver ref="form">
              <b-form @submit.prevent="submit" class="mb-3">

                <div class="form-group" ref="title">
                  <label for="titleInput">Title</label>
                  <ValidationProvider name="titleInput" rules='required' v-slot="{errors}">
                    <v-select
                      :clearable="false"
                      :options="titleOptions"
                      label="value"
                      v-model="form.title"
                      :reduce="item => item.value"
                      placeholder="Select"
                      class="form-control"
                      :class="{'is-invalid': !!errors.length}"
                    ></v-select>
                    <div class="invalid-feedback">{{ errors[0] }}</div>
                  </ValidationProvider>
                </div>

                <div class="form-group" ref="firstName">
                  <label for="firstNameInput">First name</label>
                  <ValidationProvider v-slot="{classes, errors}" rules="required" name="firstName">
                    <input v-model="form.firstName" type="text" placeholder="" name="firstNameInput" class="form-control" v-bind:class="classes">
                    <div class="invalid-feedback">{{ errors[0] }}</div>
                  </ValidationProvider>
                </div>

                <div class="form-group" ref="lastName">
                  <label for="lastNameInput">Last name</label>
                  <ValidationProvider v-slot="{classes, errors}" rules="required" name="lastName">
                    <input v-model="form.lastName" type="text" placeholder="" name="lastNameInput" class="form-control" v-bind:class="classes">
                    <div class="invalid-feedback">{{ errors[0] }}</div>
                  </ValidationProvider>
                </div>

                <div class="form-group" ref="email">
                  <label for="emailInput">Email address</label>
                  <ValidationProvider v-slot="{classes, errors}" rules="required|email" name="Email address">
                    <input ref="emailInputRef" v-model="form.email" type="text" placeholder="" autocomplete="email" name="emailInput" class="form-control" v-bind:class="classes" disabled>
                    <div class="invalid-feedback">{{ errors[0] }}</div>
                  </ValidationProvider>
                </div>

                <div class="form-group" ref="admin">
                    <div class="custom-control custom-checkbox custom-control-large">
                        <input type="checkbox" class="custom-control-input" id="admin" :value="true" v-model="form.organisationAdmin">
                        <label class="custom-control-label" for="admin">
                            <h6 class="mb-0">Organisation Admin <button v-on:click="$showInfo('OrganisationAdmin')" class="btn form-info" type="button" aria-label="Show info">?</button></h6>
                        </label>
                    </div>
                    <div class="custom-control custom-checkbox custom-control-large">
                        <input type="checkbox" class="custom-control-input" id="suparAdmin" :value="true" v-model="form.superAdmin">
                        <label class="custom-control-label" for="suparAdmin">
                            <h6 class="mb-0">Organisation Super Admin <button v-on:click="$showInfo('OrganisationSuperAdmin')" class="btn form-info" type="button" aria-label="Show info">?</button></h6>
                        </label>
                    </div>
                </div>

                <b-alert variant="danger"
                  dismissible
                  :show="showDismissibleAlert"
                  @dismissed="showDismissibleAlert=false">
                  {{serverError}}
                </b-alert>
                <button type="submit" class="btn btn-warning btn-lg btn-block">Update details</button>
              </b-form>
            </ValidationObserver> 
          </div> 
        </div>
      </div>
    </div>

  </div>

  <Footer />
</div>

</template>

<script>
import accountDetails from '@/mixins/accountDetails'
export default {
  name: 'MemberEdit',
  props: ['id'],
  mixins: [accountDetails],
  data () {
    return {
      deleteBlocker: '',
      form: {
        title: '',
        firstName: '',
        lastName: '',
        email: '',
        organisationAdmin: false,
        superAdmin: false
      },
      serverError: '',
      showDismissibleAlert: false,
      titleOptions: this.$uiValues.titleOptions,
    }
  },
  computed: {
    userId() {
      return this.$store.state.userData.id
    },
    userDisplay() {
      return `${this.form.title} ${this.form.firstName} ${this.form.lastName}`
    }
  },
  async created() {
    this.$store.state.appSection = 'appUser'
    this.$store.state.loadingProgress = true
    // redirect to Account Settings if this member is logged user
    this.id === this.userId ? this.$router.push('/account-settings') : null
    await this.getTherapist()
    this.$store.state.loadingProgress = false
  },

  methods: {
    getTherapist() {
      this.$store.state.loadingProgress = true
      return this.$http({
        method: 'get', 
        url: this.$api.TherapistAllGet,
        headers: {'Authorization': 'bearer ' + this.$store.state.jwt } 
      })
        .then((response) => {
          console.log(response)
          this.form = response.data.find(profile => profile.id === this.id)
          if (!this.form) {
            this.alertMsgShow("Profile doesn't exist", false)
            this.$router.push('/dashboard')
          }
        })
        .catch((error) => {
          console.error('Error', error)
          this.alertMsgShow("Something went wrong", false);
        })
    },

    deleteAccount: function() {
      this.$bvModal.hide('modal-delete-profile')
      this.$http({
        method: 'post', 
        url: this.$api.TherapistDelete,
        headers: {'Authorization': 'bearer ' + this.$store.state.jwt },
        params: {
          therapistId: this.form.id,
        }
      })
        .then((response) => {
          console.log(response.data)
          this.alertMsgShow("Member account deleted", true)
          this.$router.push('/members-management')
        })
        .catch((error) => {
          console.error('Error', error)
          this.alertMsgShow('Something went wrong', false)
        })
    },
    showError(txt) {
      this.serverError = txt
      this.showDismissibleAlert = true
    },

    submit() {
      this.$refs.form.validate().then(success => {
        if (!success) {
          setTimeout(() => {
            const errors = Object.entries(this.$refs.form.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error["value"].length);
            var el = this.$refs.form.refs[errors[0]["key"]].$el
            this.$scrollTo(el, 500, {offset: -100, cancelable: false})
          }, 100);
        } else {
          console.log(this.form, this.token)
          this.showDismissibleAlert = false
          this.$store.state.loadingProgress = true
          console.log('Form validated ready to submit', this.form)

          this.$http({
            method: 'post', 
            url: this.$api.TherapistUpdate,
            params: {
              ...this.form,
              admin: this.form.organisationAdmin
            },
            headers: {'Authorization': 'bearer ' + this.$store.state.jwt } 
          })
            .then((response) => {
              console.log(response)
              this.alertMsgShow('Member account has been updated', true);
            })
            .catch((error) => {
              console.error('Error', error)
              this.showError('Something went wrong. Please try again.')
            })
            .then(() => {
              this.$store.state.loadingProgress = false
            })

        }
      });
    }
  }
}
</script>
